;(function(){
async function initLunr() {
    const searchResponse = await fetch('/search.json');
    const searchJson = await searchResponse.json();
    return lunr.Index.load(searchJson);
}

let idx = null;
let pages_lookup = null;

async function getPageLookup() {
    const mapResponse = await fetch('/sitemap.json');
    const mapJson = await mapResponse.json();
    return mapJson.pages;
}

const SEARCH_MAX_RES = 10;

async function handleSearchSubmit(event) {
    event.preventDefault();
    const searchTerm = document.querySelector('.search-panel  input[name="q"]').value;
    
    if (!idx) {
        idx = await initLunr();
    }
    
    if (! pages_lookup) {
        pages_lookup = await getPageLookup();
    }
    
    const results = idx.search(searchTerm);
    results.splice(SEARCH_MAX_RES);
    // cleaning .search-results (empty div) and displaying all results inside (creating ul / li) .
    const searchResults = document.querySelector('.search-results');
    searchResults.innerHTML = '';
    const ul = document.createElement('ul');
    searchResults.appendChild(ul);
    
    results.forEach((result) => {
        const li = document.createElement('li');
        const a = document.createElement('a');
        // if doesnt start with trailing slash, add it
        a.href =  (result.ref[0] === '/' ? '' : '/') + result.ref;
        
        const corresponding_page = pages_lookup.find((p) => p.href=== result.ref);
        if (!corresponding_page) {
            console.log('no corresponding page for ' + result.ref);
            return;
        }
        
        a.textContent = corresponding_page.breadcrumb || corresponding_page.formated_title; // + ' (' + result.score + ')';
        li.appendChild(a);
        ul.appendChild(li);
    });
}

window.addEventListener('load', () => {
    document.querySelector('.search-panel form').onsubmit = handleSearchSubmit;
    document.querySelector('#search-icon').onclick = handleSearchSubmit;
});

}());